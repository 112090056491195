.title-page {
    width: 70%;
    display: block;
    margin: 0 auto;
}

.title-plaque {
        text-align: center;
        width: 250px;
        margin:  100px auto 300px;
        padding: 10px;
}

