.about {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto 100px;

    .about-image{
        display: block;
        padding: 20px;
        margin: auto;
        width: 350px;
    }
    
    .about-artist {
        display: block;
        text-align: center;
        margin: auto;
        padding: 20px;
        width: 50%;
        line-height: 50px;

        @media only screen and (max-width: 1079px) {
            width: 100%;
            line-height:normal
        }
    };


}

.artist-banner {
    width: 100%;
    margin: 0 auto 100px;


    .banner-image {
        display: block;
        width: 90%;
        margin: auto;
    }
}

.page-title {
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
}

.photo-quote-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto auto 100px;

    .photo {
        display: block;
        padding: 20px;
        margin: auto;
        width: 450px;

        @media only screen and (max-width: 700px)
            {
                width: 300px;
            }
    }

    .quote-container {
        display: block;
        width: 50%;
        text-align: center;
        margin: auto;
        line-height: 50px;
        
        @media only screen and (max-width: 1079px) {
                    width: 90%;
                    line-height:normal;
                }
    
    }

}

.wide-quote {
    width: 90%;
    text-align: center;
    margin: auto;
    line-height: 50px;

    @media only screen and (max-width: 1079px) {
        line-height:normal;
    }
}

.photo-title {
    text-align: center;
    width: 100%;
    margin: 0 auto 50px;

}

.section-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 100px;
    letter-spacing: 5px;

    @media only screen and (max-width: 700px)
    {
        margin-bottom: 50px;
    }
}

.socials {
    text-align: center;
    margin: 0 auto 100px;

    .socials-info {
        margin: 0 auto 50px;
        line-height: 50px;
        width: 50%;

        @media only screen and (max-width: 700px)
        {
            width: 90%;
            margin: 0 auto 30px;
        }
    }

    .socials-links {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: auto;
        justify-content: center;

        .link {
        height: 150px;
        display: block;
        margin: 20px
        }
    }
}