
.text-font {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto 100px;
    justify-content: space-evenly;

    .fonts {
        display: block;
        height: 100px;
        max-width: 100%;
    }
    
}

.section-title {
    text-align: center;
    margin: 100px auto
}

.title-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: space-around;

    .intro-text {
        text-align:center;
        display: block;
        width: 50%;
    }

    .title-page {
        display: block;
        width: 50%;
    }
}

.image-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: space-evenly;

    .images {
        display: block;
        height: 150px;
        max-width: 100%;
    }
}

.about-section-1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 100px auto;
    justify-content: space-evenly;

    .about-text-1 {
        width: 550px;
        line-height: 40px;
        text-align:left;

        @media only screen and (max-width: 700px) {
            width: 90%
        }        
    }

    .example {
        width: 40%;
        max-height: 250px;

        @media only screen and (max-width: 700px) {
            width: 90%
        }
    }
}


