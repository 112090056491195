.dropdown-menu {
    position: absolute;
    width: 120px;
    height: 160px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #ebe0fa;
    top: 70px;
    right: 0px;
    z-index: 5;
    animation: fade-in 1s ease;
    

    .menu-links {
        padding: 7px;

        &:hover {
            font-weight: 800;
        }
    }

}

@keyframes fade-in {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
