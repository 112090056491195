body {
  padding: 20px 60px;
  font-family: 'Noto Sans JP', sans-serif;
  
  @media only screen and (max-width: 700px) {
    padding: 5px
  }
}

a {
  color: black;

  &:hover {
    color: #7352A1;
  }
}