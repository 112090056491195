.walking-girl {
    width: 100px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;

    img {
        width: 20%;
        margin: auto;
        height: auto;
    }
}