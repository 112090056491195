.pages {
    width: 70%;
    display: block;
    margin: 300px auto 100px;
    
    @media only screen and (max-width: 700px) {
        width: 100%
    }
}

.collage-image {
    width: 60%;
    display: block;
    margin: 300px auto 100px;

    @media only screen and (max-width: 700px) {
        width: 100%
    }
}