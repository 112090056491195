.footer {
    text-align: center;

    a {
        text-decoration: none;
    }

    .footer-options {
        width: 50%;
        margin: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content:space-between;
        text-align: center;
        flex-wrap: wrap;

        .footer-option {
            padding: 10px 15px;
            cursor: pointer;

            &:hover {
                font-weight: 800;
            }
        }

        @media only screen and (max-width: 700px) {
            justify-content: space-evenly;
            width: 90%;
        }
    }
}

.copyright {
    font-size: xx-small;
}