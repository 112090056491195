.mobile-menu {
    padding: 20px;
    flex-direction: column;
    border-style: none;
    background-color: white;

    .line {
        border-top: solid;
        border-color: #7352A2;
        display: block;
        margin: 5px;
        width: 25px;
    }

    &:hover {
        background-color: #ebe0fa;
        cursor: pointer
    }
}